// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;




// Colors
$c-blue-l: #245D9B;
$c-blue-r: #0F4D92;
$c-blue-d: #0E4685;
$c-orange-l: #E3863F;
$c-orange-r: #E17A2C;
$c-orange-d: #CD6F28;
$grey-1: #e6e6e6;